/* eslint-disable */
<template>
<div>
    <main-banner :banners="banners"></main-banner>
    <section class="pedidos-section" v-if="!loading">
        <div class="container-fluid">
            <div class="section-title">
                <h4>Servicios</h4>
            </div>
            <div class="row">
                <div class="col-md-4 index-option" v-if="this.profile.admin_woe">
                    <div class="box-pedido" >
                        <router-link :to="`${route}importer`">
                          <img :src="require('@/assets/img/pedidos1.png')">
                          <h3>Importador</h3>
                        </router-link>
                    </div>
                </div>
                 <div class="col-md-4 index-option" v-if="this.profile.order_admin">
                    <div class="box-pedido" >
                        <router-link :to="`${route}pedidos`">
                          <img :src="require('@/assets/img/pedidos1.png')">
                          <h3>Pedidos</h3>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="pedidos-section" v-if="loading">
        <div class="container">
            <div class="section-title text-center">
                <h4>Cargando servicios disponibles...</h4>
            </div>
        </div>
    </section>
</div>
</template>
<script>
	import { route } from "../../enviroment.js"
    import MainBanner from "../layouts/MainBanner.vue"
    import axios from 'axios'
    export default {
        name: "HomePage",
        components: {
            MainBanner
        },
        data() {
            return {
                loading: false,
                route: route,
                banners: [],
                profile: ''
            }
        },
        created(){
            this.getUser()
        },
        methods:{
            getUser(){
                this.loading = true
                axios.get('/auth/user'
                ).then((response) => {
                    this.loading = false
                    this.profile = response.data.data.profile;
                })
                .catch((error) => {
                    if (error.code == "ERR_BAD_REQUEST"){
                        location.href= route + "login";
                    }
                });
            },
            getBanners() {
                axios.get('/banners')
                .then((response) => {
                    this.banners = response.data.data.banners
                })
                .catch((error) => {
                    if (error.code == "ERR_BAD_REQUEST"){
                        location.href= route + "login";
                    }
                })
            }
        }
    }
</script>