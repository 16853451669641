<template>
    <strong :id="`${parent}_parent`" class="btn-tooltipx" aria-describedby="tooltip" @click="show">
        <slot></slot>
    </strong>
    <div v-show="showTooltip" class="tooltipx" :id="`${parent}_tooltip`" role="tooltip">
        {{ message }}
        <div id="arrow" data-popper-arrow></div>
    </div>
    
</template>

<script>
import { createPopper } from '@popperjs/core';
import $ from 'jquery';

export default {
    name: "FileUpload",
    props: [
        'message',
        'parent'
    ],
    data: function () {
        return {
            showTooltip: false
        }
    },
    methods: {
        show() {
            $('.tooltipx').hide();
            const tooltip = document.querySelector(`#${this.parent}_tooltip`);
            const parent = document.querySelector(`#${this.parent}_parent`);
            
            this.showTooltip = !this.showTooltip;

            createPopper(parent, tooltip, {
                placement: 'top',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 8],
                        },
                    },
                ],
            });
        }
    },
};

$(function() {
    var ref = $('.btn-tooltipx');
    var popover = $('.tooltipx');
    $(document).on('click', function(e) {
        var target = $(e.target);
        if (!target.is(ref) && !target.is(popover) ) {
            popover.hide();
        }
    });
});

</script>

<style>
.tooltipx {
	display: inline-block;
	background: #ff7300;
	color: #1b1b1b;
	font-weight: 400;
	padding: 5px 10px;
	font-size: 13px;
    border-radius: 3px;
    font-family: 'Futura Std';
    font-style: normal;
    line-height: 17px;
}
  
#arrow,
#arrow::before {
	position: absolute;
	width: 8px;
	height: 8px;
	background: inherit;
}
  
#arrow {
	visibility: hidden;
}
  
#arrow::before {
	visibility: visible;
	content: '';
	transform: rotate(45deg);
}
  
.tooltipx[data-popper-placement^='top'] > #arrow {
	bottom: -4px;
}
  
.tooltipx[data-popper-placement^='bottom'] > #arrow {
	top: -4px;
}
  
.tooltipx[data-popper-placement^='left'] > #arrow {
	right: -4px;
}
  
.tooltipx[data-popper-placement^='right'] > #arrow {
	left: -4px;
}
</style>