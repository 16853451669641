<template>
  <div></div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'DownloadAttachment',
  created() {
    const attachmentId = this.$route.params.attachmentId;
    this.download(attachmentId);
  },
  methods: {
    download(attachmentId) {
      axios.post(
          'admin/order/attachment/download',
          {
            'attachmentId': attachmentId
          },
          {
            responseType: 'blob'
          }
      ).then(
          (response) => {
            const objectUrl = URL.createObjectURL(response.data);
            const a = document.createElement('a');
            a.href = objectUrl;
            a.download = 'Documento_' + attachmentId;
            a.click();
            URL.revokeObjectURL(objectUrl);
          }
      ).catch(
          (error) => console.log(error)
      );
    }
  }
}
</script>