<template>
  <div class="pedido-listado">
    <section class="pedido-steps" v-if="!mostrar_detalle">
      <div class="container-fluid">
        <div class="row orderFilters">
          <div class="col-12">
            <div class="return d-block d-sm-none">
              <router-link :to="route">
                <img src="assets/img/backspace.svg" class="mb-5" alt="backspace">
              </router-link>
            </div>
            <div class="section-title">
              <h5 class="pull-left">Listado de los pedidos que fueron enviados a McCain para su procesamiento.</h5>
              <router-link :to="route">
                <img class="d-none d-sm-block" src="assets/img/backspace.svg" alt="backspace">
              </router-link>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 mt-2">
            <div class="search-row">
              <div class="order-xs-2">
                <label class="pr-1">Fecha de Creación Desde</label>
                <input type="date" v-model="this.filters.fromCreationDate" class="datepickerstyle">
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 mt-2">
            <div class="search-row">
              <div class="order-xs-2">
                <label class="pr-1">Fecha de Creación Hasta</label>
                <input type="date" v-model="this.filters.toCreationDate" class="datepickerstyle">
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 mt-2">
            <div class="search-row">
              <div class="order-xs-2">
                <label class="pr-1">Número Pedido</label>
                <input type="text" v-model="filters.orderNumber" class="input-filter">
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 mt-2">
            <div class="search-row">
              <div class="order-xs-2">
                <label class="pr-1">Purchase order</label>
                <input type="text" v-model="filters.purchaseOrder" class="input-filter">
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 mt-2">
            <div class="search-row">
              <div class="order-xs-2">
                <label class="pr-1">Número SAP</label>
                <input type="text" v-model="filters.sapNumber" class="input-filter">
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 mt-2">
            <div class="search-row">
              <div class="order-xs-2">
                <label class="pr-1">Fecha requerida de entrega hasta</label>
                <input type="date" v-model="this.filters.fromDeliveryDate" class="datepickerstyle">
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 mt-2">
            <div class="search-row">
              <div class="order-xs-2">
                <label class="pr-1">Fecha requerida de entrega desde</label>
                <input type="date" v-model="this.filters.toDeliveryDate" class="datepickerstyle">
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 mt-2">
            <div class="search-row">
              <div class="order-xs-2">
                <label class="pr-1" data-toggle="modal" data-target="#glosarioModal">Estado <img
                    src="assets/img/help.svg" style="height: 12px; width: 25px;" alt="help"></label>
                <v-select :clearable="false" v-model="this.filters.status" :options="states" label="title"></v-select>
              </div>
            </div>
          </div>
<!--          <div class="col-md-6 col-sm-12 mt-2">-->
<!--            <div class="search-row">-->
<!--              <div class="order-xs-2">-->
<!--                <label class="pr-1">Cliente</label>-->
<!--                <v-select :clearable="false" v-model="filters.location" :options="uniqueDeliveryNames" label="label"></v-select>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <div class="col-md-12 col-sm-12 mt-3">
            <div class="search-row">
              <div class="pull-right order-xs-2">
                <a href="javascript:void(0)" @click="getOrders()" class="btn-green">Buscar</a>
                <a href="javascript:void(0)" @click="cleanFilters()" class="btn-white">Limpiar Filtros</a>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4" id="mostrarTablas">
          <div class="col-12">
            <div class="confirmado mt-2">
              <!--Para desktop-->
              <form ref="myForm">
                <table class="bg-white d-none d-sm-table" id="pedidosTable">
                  <thead>
                  <tr>
                    <th>Fecha de Creación</th>
                    <th class="text-center">Número Pedido</th>
                    <th class="text-center">Purchase order</th>
                    <th class="text-center">Número SAP</th>
                    <th class="text-center">Lugar de entrega</th>
                    <th class="text-center">Fecha requerida de entrega</th>
                    <th class="text-center">Estado</th>
                    <th class="text-center">Adjuntar Archivo</th>
                    <th class="text-center pr-4">Acción</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="b-top" v-for="(order,index) in this.paginatedOrders" :key="index">
                    <td>{{ order.date_creation.substring(0, 10)  }}</td>
                    <td class="text-center">{{ order.order_number }}</td>
                    <td class="text-center">{{ order.purchase_order }}</td>
                    <td class="text-center">{{ order.order_number_sap || '-' }}</td>
                    <td class="text-center">{{ order.delivery_name }}</td>
                    <td class="text-center">{{ order.delivery_date.substring(0, 10) }}</td>
                    <td class="text-center">
                      <div class="inline-flex align-center label-content justify-center">
                        <a href="javascript:void(0)" :class="'label-' + order.state.toUpperCase()">{{ order.state }}</a>
                      </div>
                    </td>
                    <td class="text-center">
                      <label class="file-upload">
                        <input type="file" name="file" accept=".jpeg, .png, .pdf"
                               @change="adjuntarArchivo(order, $event)" style="display: none;">
                        <img src="assets/img/add.svg" alt="Subir archivo">
                      </label>
                    </td>
                    <td class="text-center">
                      <div class="inline-flex">
                        <img class="mr-2 ml-2 pointer" @click="verPedido(order)" src="assets/img/more.svg">
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </form>

              <!--Para mobile hay que hacer una tabla por pedido-->
              <table class="bg-white d-block d-sm-none pedidosTableMobile b-top"
                     v-for="(order,index) in this.paginatedOrders" :key="index">

                <tr>
                  <th>Fecha de Creación</th>
                  <td>{{ order.date_creation.substring(0, 10)  }}</td>
                </tr>
                <tr>
                  <th class="text-center">Número Pedido</th>
                  <td class="text-center">{{ order.order_number }}</td>
                </tr>
                <tr>
                  <th class="text-center">Purchase order</th>
                  <td class="text-center">{{ order.purchase_order }}</td>
                </tr>
                <tr>
                  <th class="text-center">Número SAP</th>
                  <td class="text-center">{{ order.order_number_sap || '-' }}</td>
                </tr>
                <tr>
                  <th class="text-center">Lugar de entrega</th>
                  <td class="text-center">{{ order.delivery_name }}</td>
                </tr>
                <tr>
                  <th class="text-center">Fecha requerida de entrega</th>
                  <td class="text-center">{{ order.delivery_date.substring(0, 10) }}</td>
                </tr>
                <tr>
                  <th class="text-center">Estado</th>
                  <td class="text-center">
                    <div class="inline-flex align-center label-content justify-center">
                      <a href="javascript:void(0)" :class="'label-' + order.state">{{ order.state }}</a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th class="text-center">Acción</th>
                  <td class="text-center">
                    <div class="inline-flex align-center label-content justify-center">
                      <img class="mr-2 ml-2 pointer" @click="verPedido(order)" src="assets/img/more.svg" alt="more">
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div v-if="this.orders.length == 0 && !this.loading">
              No se encontraron resultados para su búsqueda
            </div>
          </div>
        </div>
        <div v-if="loading" class="loading"></div>
        <div class="row">
          <div class="col-md-12 text-center mb-5">
            <Vue3BsPaginate
                :total="total"
                v-model="currentPage"
                :perPage=itemsPerPage
                alignment="center"
            />
          </div>
        </div>
      </div>
    </section>
    <GlosarioModal></GlosarioModal>
    <PedidoDetalle v-if="mostrar_detalle"
                   :orderSelected="this.orderSelected"
                   @ocultarDetalleChild="ocultarDetalle"
                   @updateOrderSelected="updateOrderSelected"
    ></PedidoDetalle>
    <vue-basic-alert :duration="300" :closeIn="1000000" ref="alert"/>
  </div>
</template>

<script>
import GlosarioModal from '../layouts/GlosarioModal.vue'
import '@vuepic/vue-datepicker/dist/main.css';
import PedidoDetalle from '../pedidos/PedidoDetalle.vue'
import axios from 'axios'
import {Vue3BsPaginate} from 'vue3-bootstrap-paginate';
import {route} from "@/enviroment";

export default {
  name: 'PedidosListado',
  components: {
    vSelect: window["vue-select"],
    GlosarioModal,
    PedidoDetalle,
    Vue3BsPaginate
  },
  data: function () {
    return {
      country: '',
      filters: {
        country: '',
        fromCreationDate: '',
        toCreationDate: '',
        fromDeliveryDate: '',
        toDeliveryDate: '',
        orderNumber: '',
        purchaseOrder: '',
        sapNumber: '',
        status: '',
      },
      states: [
        {value: 'APPROVED', title: 'Aprobado'},
        {value: 'PROCESSING', title: 'Enviando'},
        {value: 'PENDING', title: 'Pendiente'},
        {value: 'PROCESSED', title: 'Aprobación Pendiente'},
        {value: 'CANCELED', title: 'Cancelada'},
        {value: 'INVOICED', title: 'Facturado'},
        {value: 'DELIVERED', title: 'Entregado'},
      ],
      originalOrders: [],
      orders: [],
      orders_data: [],
      key: '',
      mostrar_detalle: false,
      route: route,
      orderSelected: Object,
      total_paginas: '',
      loading: false,
      currentPage: 1,
      itemsPerPage: 10
    }
  },
  computed: {
    uniqueDeliveryNames() {
      const uniqueNames = new Set();
      const seenValues = new Set(); // Para llevar un registro de los valores únicos

      this.originalOrders.forEach(order => {
        const value = order.ean_delivery_location;
        if (!seenValues.has(value)) {
          seenValues.add(value);
          uniqueNames.add(order);
        }
      });

      // Create an array of objects with 'value' and 'label' properties
      return Array.from(uniqueNames).map(order => ({
        value: order.ean_delivery_location,
        label: order.delivery_name
      }));
    },
    total() {
      return this.orders.length;
    },
    paginatedOrders() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.orders.slice(startIndex, endIndex);
    },
  },
  async created() {
    await this.setCountry();
    await this.setInitialCreationDate();
    this.getOrders();
  },
  methods: {
    normalizeText(texto) {
      return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    showAlert(type, title, message) {
      this.$refs.alert.showAlert(
          type, // There are 4 types of alert: success, info, warning, error
          message, // Message of the alert
          title, // Header of the alert
          {
            iconSize: 35, // Size of the icon (px)
            iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
            position: 'top right' // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right'
          }
      )
    },
    ocultarDetalle(variable) {
      this.loading = true;
      this.mostrar_detalle = variable;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      this.loading = false;
    },
    updateOrderSelected(order) {
      const index = this.originalOrders.findIndex((original) => original.id === order.id);
      if (index > 0) this.originalOrders[index] = order;
    },
    verPedido(orderSelected) {
      this.mostrar_detalle = true;
      this.orderSelected = orderSelected;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    },
    setCountry() {
      if (sessionStorage.getItem('country')) {
        this.country = this.normalizeText(sessionStorage.getItem('country')).toUpperCase();
        this.filters.country = this.country;
      }
    },
    setInitialCreationDate() {
      const currentDate = new Date();
      const sixMonthsAgoDate = new Date();
      sixMonthsAgoDate.setMonth(currentDate.getMonth() - 6);
      this.filters.fromCreationDate = `${sixMonthsAgoDate.getFullYear()}-${String(sixMonthsAgoDate.getMonth() + 1).padStart(2, '0')}-${String(sixMonthsAgoDate.getDate()).padStart(2, '0')}`;
      this.filters.toCreationDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;
    },
    cleanFilters() {
      this.orders = this.originalOrders;
      this.currentPage = 1;
      this.filters.country = this.country;
      this.filters.fromCreationDate = '';
      this.filters.toCreationDate = '';
      this.filters.fromDeliveryDate = '';
      this.filters.toDeliveryDate = '';
      this.filters.orderNumber = '';
      this.filters.purchaseOrder = '';
      this.filters.sapNumber = '';
      this.filters.status = '';
      this.getOrders();
    },
    getFilterData() {
      let filterObject = JSON.parse(JSON.stringify(this.filters));

      if (filterObject.fromCreationDate !== undefined && filterObject.fromCreationDate !== null && !isNaN(Date.parse(filterObject.fromCreationDate))) {
        filterObject.fromCreationDate = (new Date(filterObject.fromCreationDate)).toISOString().split('T')[0];
      } else if (isNaN(Date.parse(filterObject.fromCreationDate))) filterObject.fromCreationDate = null;

      if (filterObject.toCreationDate !== undefined && filterObject.toCreationDate !== null && !isNaN(Date.parse(filterObject.toCreationDate))) {
        filterObject.toCreationDate = (new Date(filterObject.toCreationDate)).toISOString().split('T')[0];
      } else if (isNaN(Date.parse(filterObject.toCreationDate))) filterObject.toCreationDate = null;

      if (filterObject.fromDeliveryDate !== undefined && filterObject.fromDeliveryDate !== null && !isNaN(Date.parse(filterObject.fromDeliveryDate))) {
        filterObject.fromDeliveryDate = (new Date(filterObject.fromDeliveryDate)).toISOString().split('T')[0];
      } else if (isNaN(Date.parse(filterObject.fromDeliveryDate))) filterObject.fromDeliveryDate = null;

      if (filterObject.toDeliveryDate !== undefined && filterObject.toDeliveryDate !== null && !isNaN(Date.parse(filterObject.toDeliveryDate))) {
        filterObject.toDeliveryDate = (new Date(filterObject.toDeliveryDate)).toISOString().split('T')[0];
      } else if (isNaN(Date.parse(filterObject.toDeliveryDate))) filterObject.toDeliveryDate = null;

      if (filterObject.status !== undefined && filterObject.status !== null && filterObject.status !== 'undefined' && filterObject.status !== 'null') {
        filterObject.status = this.filters.status.value;
      }

      return filterObject;
    },
    getFilterQuery() {
      const query = new URLSearchParams(this.getFilterData()).toString();
      return (query) ? `&${query}` : ``;
    },
    getOrders(page = 1) {
      this.loading = true;
      axios.get(`/admin/orders?page=${page}${this.getFilterQuery()}`)
          .then(async (res) => {
            if (res.status === 200 && res.data.status === 'success') return res.data.data;
            else throw new Error(res.data.message);
          })
          .then(async (data) => {
            //if (data.status.toString() === 'error') throw new Error(data.message);
            this.orders = data
            this.originalOrders = data
            this.paginatedOrders = data
            this.currentPage = 1;
            this.loading = false;
          })
          .catch((error) => {
            let msg = '';
            if (error.status && error.status === 200) msg = error.message;
            else msg = error;
            this.cuotas = [];
            this.showAlert('warning', 'Atención!', msg);
            this.loading = false;
          });
    },
    adjuntarArchivo(orderSelected, event) {
      if (!!event.target.files[0] && /\.(jpeg|png|pdf)$/i.test(event.target.files[0].name)) {
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        formData.append('orderNumber', orderSelected.order_number);
        this.loading = true;
        axios.post('admin/order/attachment', formData).then((response) => {
          this.loading = false
          this.showAlert('success', 'Archivo subido con exito!', 'Archivo subido con exito!');
          console.log(response);
        }).catch((error) => {
          console.log(error)
          this.showAlert('warning', error.message, error.message);
        });
      } else {
        this.showAlert('warning', "Formato de archivo no permitido. Por favor, seleccione un archivo .jpeg, .png o .pdf.", "Formato de archivo no permitido. Por favor, seleccione un archivo .jpeg, .png o .pdf.");
      }
    },
  }
}
</script>
<style>
.pagination li {
  margin: 0 !important;
}
.datepickerstyle {
       border: 1px solid #DADADA;
       width: 100%;
       height: 50px !important
}
</style>
<style>
@import "vue-select/dist/vue-select.css";

@media screen and (max-width: 768px){
  .v-select, .v-select * {
    box-sizing: border-box;
    font-size: 11px;
}
}

</style>