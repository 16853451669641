import "vue-multiselect/dist/vue-multiselect.css"
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Modal from "vue-bs-modal";
import Datepicker from '@vuepic/vue-datepicker';
import VueBasicAlert from 'vue-basic-alert'
import '@vuepic/vue-datepicker/dist/main.css'
import VueCookies from 'vue-cookies'
import VueMultiselect from 'vue-multiselect'
import VueSelect from "vue-select";


const app = createApp(App);

app
.use(router)
.use(Modal)
.use(VueCookies)
.component('Datepicker', Datepicker)
.component("v-select", VueSelect)
.component('VueBasicAlert', VueBasicAlert)
.component('VueMultiselect', VueMultiselect)
.mixin({
    mounted() {
      document.title = 'Admin McCain';
    }
})
.mount('#app')

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('./sw.js');
    });
}