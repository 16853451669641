<template>
  <section class="pedido-steps">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="section-title">
            <h4>Resumen</h4>
            <a @click="volverListado()" href="javascript:void(0)"><img class="d-none d-sm-block"
                                                                       src="assets/img/backspace.svg"></a>
          </div>
        </div>
      </div>
      <div class="loading" v-if="loading"></div>
      <div class="row mostrarPedido" v-if="!loading">
        <div class="col-md-12">
          <div class="confirmado">
            <div class="section-title p-0">
              <h5>Orden Resumen</h5>
            </div>
            <!-- desktop -->
            <table class="bg-white d-sm-table">
              <tr>
                <th>EAN</th>
                <th>Nombre</th>
                <th>Material</th>
                <th>Peso Bruto</th>
                <th>Peso Neto</th>
                <th>Volumen</th>
                <th>Quantity</th>
                <th>Tipo</th>
                <th># Pallet</th>
              </tr>
              <tr class="b-top" v-for="(item,index) in this.items" :key="index">
                <td>{{ item.ean }}</td>
                <td>{{ item.sku_name }}</td>
                <td>{{ item.partner_code }}</td>
                <td>{{ item.weight_gross_metric }}</td>
                <td>{{ item.weight_net_metric }}</td>
                <td>{{ item.volume_metric }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ item.product_type }}</td>
                <td>{{ item.pallet_quantity }}</td>
              </tr>
            </table>
            <!-- mobile -->
            <div class="pedidosDetalle">
              <table class="bg-white d-block pedidosTableMobile b-top" v-for="(item,index) in this.items" :key="index">
                <tr>
                  <th>EAN</th>
                  <td>{{ item.ean }}</td>
                </tr>
                <tr>
                  <th>Nombre</th>
                  <td>{{ item.sku_name }}</td>
                </tr>
                <tr>
                  <th>Material</th>
                  <td>{{ item.material }}</td>
                </tr>
                <tr>
                  <th>Peso Bruto</th>
                  <td>{{ item.weight_gross_metric }}</td>
                </tr>
                <tr>
                  <th>Peso Neto</th>
                  <td>{{ item.weight_net_metric }}</td>
                </tr>
                <tr>
                  <th>Volumen</th>
                  <td>{{ item.volume_metric }}</td>
                </tr>
                <tr>
                  <th>Quantity</th>
                  <td>{{ item.quantity }}</td>
                </tr>
                <tr>
                  <th>Tipo</th>
                  <td>{{ item.product_type }}</td>
                </tr>
                <tr>
                  <th># Pallet</th>
                  <td>{{ item.pallet_quantity }}</td>
                </tr>
              </table>
            </div>
            <div class="yellow-bg">
              <div class="inline-table">
                <span class="pull-left">Fecha requerida de entrega: <b>{{ orderSelected.delivery_date.substring(0, 10) }}</b></span>
                <span class="pull-right">Incoterm: <b>{{ orderSelected.incoterm }}</b></span>
              </div>
              <div>
                <span class="pull-left">Lugar de entrega: <b>{{ orderSelected.delivery_name }}</b></span>
              </div>
              <br>
              <div>
                <span class="nro-orden">Nro. Orden: <b>{{ orderSelected.order_number }}</b></span>
              </div>
              <div class="row mt-5" v-if="order">
                <div class="col-md-12">
                  Peso Total Bruto: <b> {{
                    (medicion == 'metrico')
                        ? orderSelected.total_weight_net_metric + ' kg'
                        : orderSelected.total_weight_net_imperial + ' lbs'
                  }}</b>
                </div>
                <div class="col-md-12">
                  Peso Total Neto: <b> {{
                    (medicion == 'metrico')
                        ? orderSelected.total_weight_gross_metric + ' kg'
                        : this.orderSelected.total_weight_gross_imperial + ' lbs'
                  }}</b>
                </div>
                <div class="col-md-12">
                  Volumen Total: <b> {{
                    (medicion == 'metrico')
                        ? orderSelected.total_volume_metric + ' m'
                        : orderSelected.total_volume_imperial + ' ft'
                  }}<sup>3</sup></b>
                </div>
                <div class="col-md-12">
                  Total Pallets: <b>{{ orderSelected.total_pallet_numbers }}</b>
                </div>
              </div>
              <div class="row mt-5" v-if="order.state">
                <div class="col-md-12">
                  Estado:
                  <div class="inline-flex align-center label-content justify-center">
                    <a href="javascript:void(0)" :class="'label-' + order.state">{{ order.state }}</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 next-btn mt-5 mb-4">
                <a v-if="order.state.toUpperCase() === 'PENDING'"
                   @click="approveReject('approve')" href="javascript:void(0)" class="btn-white">Aprobar</a>
                <a v-if="order.state.toUpperCase() === 'PENDING'"
                   @click="approveReject('reject')" href="javascript:void(0)" class="btn-white">Cancelar</a>
                <a @click="volverListado()" href="javascript:void(0)" class="btn-white">Volver al listado</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <vue-basic-alert :duration="300" :closeIn="1000000" ref="alert"/>
    </div>
  </section>
</template>
<script>
import {route} from '@/enviroment';
import axios from 'axios';

export default {
  name: "PedidoDetalle",
  props: {
    orderSelected: Object
  },
  data() {
    return {
      loading: false,
      route: route,
      order: {},
      items: [],
      medicion: 'metrico',
      action: {
        approval: '',
        orderId: ''
      }

    }
  },
  created() {
    this.getOrderItems();
  },
  methods: {
    showAlert(type, title, message) {
      this.$refs.alert.showAlert(
          type, // There are 4 types of alert: success, info, warning, error
          message, // Message of the alert
          title, // Header of the alert
          {
            iconSize: 35, // Size of the icon (px)
            iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
            position: 'top right' // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right'
          }
      )
    },
    volverListado() {
      this.$emit("ocultarDetalleChild", false);
    },
    approveReject(action) {
      this.loading = true;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      this.action.approval = action;
      this.action.orderId = this.orderSelected.id;
      axios.post('admin/order/approval', this.action)
          .then((response) => {
            const res = response.data;
            if (res.status === 'error') {
              this.showAlert('error', 'Atención', res.message);
              this.loading = false;
              return;
            }
            const data = res.data;
            if (data.status === 'error') {
              this.showAlert('warning', data.error.title, data.error.description);
              this.loading = false;
              return;
            }
            this.order = data.order;
            this.$emit("updateOrderSelected", this.order);
            this.showAlert('success', 'Atención', data.message);
            this.loading = false;
          })
          .catch((error) => {
            this.showAlert('warning', error.message, error.message);
          });
    },
    getOrderItems() {
      this.loading = true;
      this.order = this.orderSelected;
      axios.get('admin/order/items/' + this.orderSelected.id)
          .then((response) => {
            this.items = response.data.data;
            this.loading = false;
          })
          .catch((error) => {
            this.showAlert('warning', error.message, error.message);
          });
    }
  }

}
</script>