import {createRouter, createWebHistory} from 'vue-router'
import IngresoComponent from '../components/pages/IngresoComponent.vue'
import ForgotPassword from '../components/auth/ForgotPassword.vue'
import ResetPassword from '../components/auth/ResetPassword.vue'
import Home from '../components/pages/Home.vue'
import PedidosListado from '../components/pedidos/PedidosListado.vue'
import DownloadAttachment from '../components/pedidos/DownloadAttachment';
import ImporterComponent from '../components/pages/Importador.vue'
import {route} from "../enviroment.js"
import axios from 'axios';

const routes = [
    {
        path: route + 'login',
        name: 'login',
        component: IngresoComponent,
        meta: {requiresAuth: false}
    },
    {
        path: route + '',
        name: 'home',
        component: Home,
        meta: {requiresAuth: true}
    },
    {
        path: route + 'forgot-password',
        name: 'forgot-password',
        component: ForgotPassword,
        meta: {requiresAuth: false}
    },
    {
        path: route + 'reset-password',
        name: 'reset-password',
        component: ResetPassword,
        meta: {requiresAuth: false}
    },
    {
        path: route + 'importer',
        name: 'importer-componente',
        component: ImporterComponent,
        meta: {requiresAuth: true}
    },
    {
        path: route + 'pedidos',
        name: 'pedidos-listado',
        component: PedidosListado,
        meta: {requiresAuth: true}
    },
    {
        path: route + 'order/attachment/:attachmentId',
        name: 'download-attachment',
        component: DownloadAttachment,
        meta: {
            requiresAuth: false
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

//check for user logged in on auth sections
router.beforeEach((to, from, next) => {
    // instead of having to check every route record with
    if (to.meta.requiresAuth) {
        if (!sessionStorage.getItem('name')) {
            next({
                path: route + 'login',
                query: {redirect: to.fullPath}
            });
        }
    }
    next();
});

//handle 401 response -> redirect 
axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        if (error.response.config.url.includes('login')) {
            return Promise.reject(error);
        }
        location.href = route + "login";
    } else {
        return Promise.reject(error);
    }
});

axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    config.withCredentials = true;
    config.baseURL = process.env.VUE_APP_BACKEND_API_URL;
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default router;
