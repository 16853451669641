<template>
	<div>
		<section style="background: black;">
			<!-- Place somewhere in the <body> of your page -->
			<div class="flexslider" style="border:0; margin:0">
				<ul class="slides">
					<li>
						<img :src="require('@/assets/img/home/bg-1.jpg')" />
					</li>
					<li>
						<img :src="require('@/assets/img/home/bg-3.jpg')" />
					</li>
					<li>
						<img :src="require('@/assets/img/home/bg-4.jpg')" />
					</li>
					<li>
						<img :src="require('@/assets/img/home/bg-5.jpg')" />
					</li>
				</ul>
			</div>
			<div class="overlay">
				<div class="container">
					<div class="row align-center">
						<div class="col-md-6 text-left">
							<div class="logo">
								<nav class="navbar navbar-expand-lg navbar-light">
									<button class="navbar-toggler" type="button" data-toggle="collapse"
										data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
										aria-expanded="false" aria-label="Toggle navigation">
										<img :src="require('@/assets/img/menu.svg')">
									</button>
									<a class="navbar-brand" href="javascript:void(0)">
										<img :src="require('@/assets/img/logo.png')">
									</a>
								</nav>
							</div>
						</div>
						<!-- <div class="col-md-6 text-right">
							<ul class="navbar-desktop">
								<li><router-link class="menu-item" :to="route + 'contacto'">Contáctenos</router-link></li>
							</ul>
						</div> -->
					</div>
				</div>
				<div class="main-elements">
					<div class="container">
						<div class="row">
							<div class="collapse navbar-collapse" id="navbarSupportedContent">
								<ul class="navbar-nav mr-auto">
									<li class="nav-item">
										<router-link :to="route + 'contacto'" class="nav-link menu-item	" href="#">Contáctenos</router-link>
									</li>
								</ul>
							</div>
							<div class="col-md-8 self-center">
								<h1>Si esta McCain <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sientes el sabor</h1>
							</div>
							<div class="col-md-4 self-center init-sesion">
								<div class="white-box">
									<div class="text-center" v-if="!enable_login" >
										<h4>Seleccione pais</h4>
										<form @submit.prevent="onSubmit">
											<div class="row">
												<div class="col-md-12">
													<select class="select-input" name="comunicacion" v-model="country" placeholder="Seleccione pais">
														<option  v-for="(country,index) in this.countries" :key="index" :value="country">{{country}}</option>
													</select>
												</div>
											</div>
											<p v-if="this.error_message!=''" class="password-forget invalid-password" style="color: red;">{{ error_message }}</p>
											<button class="pointer" @click="setCountry">Continuar</button>
										</form>
									</div>
									<div class="text-center" v-if="enable_login">
										<h4>Iniciar Sesión</h4>
										<form @submit.prevent="onSubmit">
											<div class="row">
												<div class="col-md-12">
													<input v-model="usuario" type="text" name="" placeholder="Usuario">
												</div>
												<div class="col-md-12">
													<input v-model="password" type="password" placeholder="Contraseña" name="">
												</div>
											</div>
											<!-- <router-link> -->
												<!-- <p class="password-forget">¿Olvidó su contraseña?</p> -->
											<!-- </router-link>                							 -->
											<p v-if="this.error_message!=''" class="password-forget invalid-password" style="color: red;">{{ error_message }}</p>
											<button :disabled="loading" class="pointer" @click="login">Ingresar</button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<FooterCopyright></FooterCopyright>
	</div>
</template>

<script>
import FooterCopyright from '../layouts/FooterCopyright.vue'
import axios from 'axios'
import {route} from "../../enviroment.js"

export default {
		name: 'IngresoComponent',
		data: function () {
			return {
				usuario: '',
				loading: false,
				password: '',
				country: null,
				countries: [
					'Argentina',
					'Calatin',
					'Colombia',
					'México',
					'Uruguay'
				],
				route : route,
				enable_login: false,
				error_message: null
			}
		},
		components: {
			FooterCopyright
		},
		methods: {
			login() {
				this.loading = true
                axios.post('/auth/signin', 
					{
						"principal": this.usuario,
						"password": this.password
					},
					).then(async (response)=> {
						this.loading = false;
						let countryName = '';
						if (response.data.data.profile.address.countryName) {
							countryName = response.data.data.profile.address.countryName;
						}

					const country = this.normalizeText(sessionStorage.getItem('country').toString().toLowerCase());
					if(countryName.toLowerCase().includes(country)){
            const adminWoe = response.data.data.profile.admin_woe;
            const orderAdmin = response.data.data.profile.order_admin;
            if (!adminWoe && !orderAdmin) {
              this.error_message = "Contacte al responsable para solicitar inclusión de perfil.";
              return false;
            }
						sessionStorage.setItem('name', "admin " + countryName);
						this.$route.query.redirect
						? this.$router.push(this.$route.query.redirect)
						:  this.$router.push('/');
					}else{
						this.loading = false;
						this.error_message = "El usuario no corresponde al país";
					}
					}).catch(() => {
						this.loading = false;
						this.error_message = "Usuario o contraseña inválidos";
						}); 
					},
			setCountry(){
				if(this.country){
					sessionStorage.setItem('country', this.country);
					this.enable_login = true;
					this.error_message = '';
				}else{
					this.error_message = "Seleccione el país";
				}
			},
			normalizeText(text){
				return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
			}
		}
	}
</script>