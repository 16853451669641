<template>
   <div class="modal fade" id="glosarioModal" tabindex="-1" role="dialog" aria-labelledby="glosarioModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                <h5 class="modal-title" id="glosarioModal">Glosario de etiquetas</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                <ul>
                    <li>
                        <div class="label-content">
                            <a class="label-CANCELED" href="">Cancelada</a>
                        </div>
                        <span>Cancelada por el vendedor</span>
                    </li>
                    <li>
                        <div class="label-content">
                            <a class="label-PENDING" href="">Pendiente</a>
                        </div>
                        <span>Esperando aprobación</span>
                    </li>
                    <li>
                        <div class="label-content">
                            <a class="label-PROCESSING" href="">Enviando</a>
                        </div>
                        <span>El pedido se esta enviándo desde el Portal a McCain</span>
                    </li>
                    <li>
                        <div class="label-content">
                            <a class="label-PROCESSED" href="">Pendiente de Aprobacion</a>
                        </div>
                        <span>El pedido está siendo validado en SAP McCain.</span>
                    </li>
                    <li>
                        <div class="label-content">
                            <a class="label-APPROVED" href="">Aprobado</a>
                        </div>
                        <span>El pedido se ha aprobado por Créditos</span>
                    </li>
                    <li>
                        <div class="label-content">
                            <a class="label-INVOICED" href="">En proceso de facturacion</a>
                        </div>
                        <span>El pedido está en instancia de facturación para su posterior entrega.</span>
                    </li>
                </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "GlosarioModal",
        props: {

        },
        data() {
            return {}
        },
    }
</script>